import styled from "styled-components";
import { Flex, Grid } from "aplication-yetz";
import lupaImg from "../../assets/sistema/lupa.svg";
import imgClose from "../../assets/sistema/fechar-modal.svg";
import imgPlayer from "../../assets/sistema/player.svg";

export const HeaderStyles = styled(Flex)`
    height: 101px;
    align-items: center;
    border-bottom: 1px solid #e3e3e3;
    margin-bottom: 59px;
    @media (max-width: 480px) {
        div {
            img {
                margin: 0 auto;
                display: block;
            }
        }
    }
`;

export const SubHeaderStyles = styled.div`
    .container-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 66px;
    }

    @media (max-width: 566px) {
        .container-header {
            flex-direction: column;
            width: 100%;
            text-align: center;
            .filtro {
                width: 100%;
            }
        }
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    .filtro {
        position: relative;
        input {
            padding-right: 25px;
        }
        &:before {
            content: "";
            display: block;
            width: 18px;
            height: 18px;
            -webkit-mask: url(${lupaImg}) no-repeat center;
            -webkit-mask-size: cover;
            position: absolute;
            background-color: ${({ theme }) => theme.colors.primary};
            top: 60%;
            transform: translateY(-50%);
            right: 0px;
        }
    }
`;

export const Link = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    color: ${({ theme }) => theme.colors.primary};
`;

export const CardStyles = styled.div`
    border-radius: 8px;
    border: 1px solid #e1e1e1;
    padding: 24px 32px;
    width: 100%;
    .container-text-img {
        border-bottom: 1px solid #dfdfdf;
        margin-bottom: 26px;
        .container-img {
            margin-bottom: 12px;
            img {
                width: 92px;
                height: 62px;
                border-radius: 4px;
            }
        }
    }
`;

export const CardsStyles = styled(Grid)`
    grid-template-columns: repeat(auto-fit, minmax(296px, 295px));
    grid-gap: 32px;
    margin-bottom: auto;
    @media (max-width: 1366px) {
        grid-template-columns: repeat(auto-fit, minmax(296px, 1fr));
    }
`;

export const FooterStyles = styled(Flex)`
    border-top: 1px solid #cfcfcf;
    height: 153px;
    align-items: center;
    justify-content: center;
    margin-top: 138px;
    @media (max-width: 480px) {
        width: 90%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
`;

export const ModalStyles = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    width: 500px;
    padding: 44px 24 0 35px;
    border: 1px solid #5e5f5f;
    border-radius: 19px;
    height: 90vh;
    overflow: scroll;
    overflow-x: hidden;
    position: relative;
    .container-btn {
        button {
            width: 24px;
            height: 24px;
            position: relative;
            &:before {
                content: "";
                width: 100%;
                height: 100%;
                display: block;
                -webkit-mask: url(${imgClose});
                -webkit-mask-repeat: no-repeat;
                position: absolute;
                top: 0px;
                left: 0px;
                background-color: ${(props) => props.theme.colors.primary};
            }
        }
    }
    //#930035

    &.stone {
        .container-instrucoes {
            .instrucoes {
                .player-yetz {
                    &:before {
                        background-color: #000000;
                    }
                }
                button {
                    width: 70%;
                    background-color: #00ea33;
                    color: #2b323b;
                    border-radius: 8px;
                }
            }
        }
        .container-btn {
            button {
                &:before {
                    background-color: #017f1cfa;
                }
            }
        }
        .content-header-modal {
            .text {
                width: 320px;
                display: flex;
                align-items: center;
                justify-content: center;
                span {
                    line-height: normal;
                    width: 266px;
                    display: block;
                    color: #2b323b;
                    strong {
                        color: #017f1cfa;
                        text-transform: uppercase;
                        width: 100%;
                        display: block;
                        white-space: nowrap;
                    }
                }
            }
        }
        .container-cards {
            .cards {
                .container-informacoes {
                    h3 {
                        color: #017f1cfa;
                    }
                }
                .card-atencao {
                    h4 {
                        color: #017f1cfa;
                    }
                    small {
                        background: #31fb47fa;
                        color: #000e18;
                    }
                }
            }
        }
        .container-instrucoes {
            background: #f7f7f7;
            .instrucoes {
                p {
                    color: #2b323b;
                    font-size: 29px;
                    strong {
                        color: #017f1cfa;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

    .container-btn {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 99;
    }
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 5px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 12px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(89, 92, 118, 0.5);
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.1);
    }

    .content-header-modal {
        text-align: center;
        height: 242px;
        position: relative;
        &:before {
            content: "";
            display: block;
            width: 100%;
            height: 2px;
            background-color: #5e5f5f;
            position: absolute;
            top: 25%;
            transform: translateY(-50%);
            z-index: 10;
        }
        .text {
            padding-top: 20px;
            position: relative;
            z-index: 20;
            background-color: ${({ theme }) => theme.colors.white};
            width: 50%;
            margin: 0 auto;
            span {
                font-family: Lato;
                font-style: normal;
                font-weight: normal;
                font-size: 2rem;
                line-height: 77px;
                color: #5e5f5f;
            }

            p {
                font-family: Lato;
                font-style: normal;
                font-weight: 800;
                font-size: 2rem;
                line-height: 52px;
                text-transform: uppercase;
                color: ${({ theme }) => theme.colors.primary};
                margin-top: -10px;
            }
        }
    }

    .container-instrucoes {
        background: ${({ theme }) => theme.colors.orange200};
        position: relative;
        top: -45px;
        height: 220px;

        .instrucoes {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            width: 90%;
            margin: 0 auto;
            position: relative;
            top: -75px;
            img {
                &.logo {
                    margin-bottom: 20px;
                    width: 40%;
                    border-radius: 8px;
                    object-fit: cover;
                }
            }

            .player-yetz {
                width: 25px;
                height: 25px;
                position: relative;
                margin-right: 12px;
                &:before {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 100%;
                    -webkit-mask: url(${imgPlayer}) no-repeat center;
                    -webkit-mask-size: cover;
                    position: absolute;
                    background-color: ${({ theme }) => theme.colors.white};
                    top: 0px;
                    right: 0px;
                }
            }

            button {
                font-family: Lato;
                font-style: normal;
                font-weight: 800;
                font-size: 0.75rem;
                line-height: 33px;
                color: ${({ theme }) => theme.colors.white};
                position: relative;
                background: ${({ theme }) => theme.colors.primary};
                border-radius: 25.6034px;

                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 12px;

                padding: 5px 25px;

                img {
                    display: block;
                    margin-right: 12px;
                    width: 25px;
                }
            }

            p {
                font-family: Lato;
                font-style: normal;
                font-weight: normal;
                font-size: 1.3rem;
                text-align: center;
                color: ${({ theme }) => theme.colors.white};
                strong {
                    font-weight: 700;
                }
            }
        }
    }

    .container-cards {
        width: 90%;
        margin: 0 auto;
        .cards {
            width: 100%;
            position: relative;
            top: -75px;
            .card-atencao {
                border: 1px solid #5e5f5f;
                border-radius: 19px;
                background-color: #f1f1f1;
                padding: 20px;
                width: 100%;
                margin: 0 auto;
                margin-bottom: 24px;
                position: relative;
                &:last-child {
                    margin-bottom: 0px;
                }
                &.atencao {
                    padding-top: 24px;
                    h4 {
                        margin-bottom: 5px;
                    }
                }

                h4 {
                    font-family: Lato;
                    font-style: normal;
                    font-weight: 800;
                    font-size: 35px;
                    line-height: 42px;
                    text-align: center;
                    color: ${({ theme }) => theme.colors.primary};
                }

                p {
                    font-family: Lato;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 1rem;
                    color: #5e5f5f;
                }

                small {
                    background: ${({ theme }) => theme.colors.primary};
                    border-radius: 25.6034px;

                    font-family: Lato;
                    font-style: normal;
                    font-weight: 800;
                    font-size: 1rem;
                    line-height: 33px;
                    padding: 0px 15px;
                    /* Branco Yetz */

                    color: ${({ theme }) => theme.colors.white};
                    position: absolute;
                    top: -18px;
                    left: 23px;
                }
            }

            .container-informacoes {
                display: grid;
                grid-template-columns: 50% 50%;
                grid-gap: 0px 1rem;
                p {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 1rem;
                    color: #5e5f5f;
                }
                h3 {
                    font-style: normal;
                    font-size: 0.8rem;
                    text-transform: uppercase;
                    color: ${({ theme }) => theme.colors.primary};
                    margin-bottom: 16px;
                }
                .container-esq,
                .container-dir {
                    width: 100%;
                    .item {
                        border-bottom: 1px solid #5e5f5f66;
                        padding-top: 22px;
                        &:last-child {
                            border-bottom: none;
                        }
                        &.descricao {
                            padding-bottom: 44px;
                        }

                        &.info {
                        }

                        &:first-child {
                            padding-top: 0px;
                            p {
                                padding-bottom: 44px;
                            }
                        }
                        button {
                            background: #f17e21;
                            height: 42px;
                            display: flex;
                            align-items: center;
                            border-radius: 30px;
                            display: flex;
                            padding-left: 25px;
                            padding-right: 25px;
                            align-items: center;
                            margin-bottom: 32px;
                            width: auto;

                            span {
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                color: ${({ theme }) => theme.colors.white};
                                font-style: normal;
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 20px;
                                letter-spacing: 0.24px;
                            }
                            img {
                                margin-right: 17px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 1080px) {
        margin: 0 auto;
        .container-cards {
            .cards {
                .card-atencao {
                }
            }
        }
    }

    @media (max-width: 556px) {
        height: 100vh;
        border-radius: 0px;
        .content-header-modal {
            &:before {
                display: none;
            }
            .text {
                width: 100%;
                span {
                    font-size: 1.5rem;
                }

                p {
                    font-size: 1.5rem;
                    line-height: normal;
                }
            }
        }

        .container-instrucoes {
            top: -85px;
            .instrucoes {
                width: 100%;
                top: -55px;
                img {
                    &.logo {
                        width: 150px;
                        height: 92px;
                    }
                }

                button {
                    font-size: 14.6103px;
                    padding-top: 3px;
                    padding-bottom: 5px;
                    img {
                        width: 16px;
                    }
                }

                p {
                    font-size: 21.1471px;
                    line-height: 26px;
                    width: 90%;
                }
            }
        }

        .container-cards {
            .cards {
                top: -125px;
                .card-atencao {
                    &.atencao {
                        padding-top: 10px;
                        h4 {
                            font-size: 1.5rem;
                            line-height: normal;
                        }
                    }
                    span {
                        padding: 0px;
                        font-size: 1rem;
                        border-radius: 13.5px;
                    }
                }
            }
        }
    }

    @media (max-width: 480px) {
        &.stone {
            .container-instrucoes {
                .instrucoes {
                    p {
                        font-size: 5.7vw;
                    }
                }
            }
        }
        .container-cards {
            .cards {
                .container-informacoes {
                    .container-esq {
                        .item {
                            button {
                                img {
                                    width: 20px;
                                    height: 30px;
                                    margin-right: 0.5rem;
                                }
                                span {
                                    font-size: 9px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const VideoStyles = styled.div`
    background-color: #000;
    position: relative;
    button {
        margin-left: auto;
        width: fit-content;
        display: block;
        margin-bottom: 8px;
        position: absolute;
        top: -30px;
        right: 0px;
    }
    @media (max-width: 1080px) {
        margin: 0 auto;
        div[data-vimeo-initialized] {
            width: 100%;
            min-width: fit-content;
            iframe {
                width: 100%;
                height: 66vh;
                margin: 0 auto;
                body {
                    height: auto;
                }
            }
        }
    }
`;
