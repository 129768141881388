import { FormProduto } from "./types";
import * as Yup from "yup";

export const initialValues: FormProduto = {
    nome: "",
    tipo: "",
    categorias: [],
    status: "",
    descricao: "",
    detalhes: "",
    informacoes_importantes: "",
    informacoes_temporarias: "",
    url_tutorial: "",
    foto_capa: "",
    selo: "",
    alerta_estoque: "",
    desconto_outlet: "",
    data_outlet: "",
    template: "",
    validade: "",
    template_voucher_id: "",
    external_link: "",
    external_link_label: "",
    template_validade_fixa: "",
    // cupom: "",
    // tutorial_passos: "",
    // tutorial_observacao: "",
    // tutorial_url: "",
    // utilizado_em: "",
};

export const addSchema = Yup.object().shape({
    nome: Yup.string().required("Campo obrigatório"),
    tipo: Yup.string().required("Campo obrigatório"),
    categorias: Yup.array().of(Yup.number()),
    status: Yup.string(),
    template_validade_fixa: Yup.string(),
    // cupom: Yup.string().required("Campo obrigatório"),
    descricao: Yup.string(),
    detalhes: Yup.string(),
    informacoes_importantes: Yup.string(),
    informacoes_temporarias: Yup.string(),
    url_tutorial: Yup.string(),
    foto_capa: Yup.string().required("Campo obrigatório"),
    selo: Yup.string().required("Campo obrigatório"),
    alerta_estoque: Yup.string().required("Campo obrigatório"),
    desconto_outlet: Yup.string().required("Campo obrigatório"),
    data_outlet: Yup.string().required("Campo obrigatório"),
    template: Yup.string(),
    validade: Yup.string(),
    tutorial_url: Yup.string(),
    template_voucher_id: Yup.string(),
    external_link: Yup.string(),
    external_link_label: Yup.string(),
});

// export const updateSchema = Yup.object().shape({
//     name: Yup.string().required("Nome é obrigatório"),
//     email: Yup.string()
//         .email("E-mail inválido")
//         .required("E-mail é obrigatório"),
//     phone_number: Yup.string().required("Celular é obrigatório"),
//     status: Yup.string(),
//     role: Yup.string().required("Perfil é obrigatório"),
// });
