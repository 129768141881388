import imgUber from "../../../assets/sistema/img_uber.png";
import imgCopy from "../../../assets/sistema/copy.svg";
// import imgMensagem from "../../../assets/sistema/mensagem-stone.svg";

import { Container } from "./styles";
import { ResponseVoucher } from "../../../pages/MultiplosVoucherGrandCru";
import { formatCurrentMoney, formatDateIso } from "../../../util/helper";
import { toast } from "react-toastify";

interface StoneProps {}

interface StoneProps {
    setModalTutorial: (value: boolean) => void;
    voucher: ResponseVoucher;
    loading: boolean;
    name?: string;
}

export function Stone(props: StoneProps) {
    const validade = props?.voucher?.voucher
        ? props.voucher?.voucher[0].validade
        : "";
    const foto = props?.voucher?.voucher?.[0]?.foto_capa ?? imgUber;
    const password = props?.voucher?.voucher
        ? props.voucher.voucher[0].password
        : "";
    const data = props?.voucher?.voucher
        ? formatDateIso(props?.voucher?.voucher[0].date || "")
              .split("-")[0]
              .replace(/\./g, "/")
        : "";
    function copy(text: string) {
        const el = document.createElement("textarea");
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        toast.success(`Copiado`);
        return "Texto copiado com sucesso!";
    }

    return (
        <Container>
            <div className="content-stone">
                <div className="container-img">
                    <img src={foto} alt="" />
                </div>

                <div className="description">
                    <h3>Confira seu benefício</h3>
                    <h4>
                        {props.name} •{" "}
                        {formatCurrentMoney(
                            parseFloat(
                                props?.voucher?.voucher
                                    ? props?.voucher?.voucher[0].valor + ""
                                    : ""
                            )
                        )}
                    </h4>
                </div>

                <div className="container-voucher">
                    <div className="container-titulo">
                        <h3>código</h3>
                    </div>

                    <div className="codigo">
                        <h5>
                            {props.voucher.voucher
                                ? props.voucher.voucher[0].codes[0]
                                : ""}
                        </h5>
                        <button
                            className="btn-copy"
                            onClick={() =>
                                copy(
                                    props.voucher.voucher
                                        ? props.voucher.voucher[0].codes[0]
                                        : ""
                                )
                            }
                        >
                            <img src={imgCopy} alt="" />
                            copiar
                            <br />
                            código
                        </button>
                    </div>

                    {props?.voucher?.voucher &&
                    props?.voucher?.voucher[0].password ? (
                        <div className="item">
                            <div className="senha">
                                <span>senha:</span>
                                <p className="roboto-mono">{password}</p>
                            </div>
                            <button
                                className="btn-copy"
                                onClick={() => copy(password || "")}
                            >
                                <img src={imgCopy} alt="" />
                                copiar
                                <br />
                                senha
                            </button>
                        </div>
                    ) : null}

                    <div className="container-validade">
                        <p>
                            {validade === "VENCIDO" && (
                                <small>Vencido em: {data}</small>
                            )}
                            {validade === "VENCIMENTO" && (
                                <small>Vencimento em: {data}</small>
                            )}
                            {validade !== null &&
                            validade !== "VENCIDO" &&
                            validade !== "VENCIMENTO" ? (
                                <small>
                                    Validade: {validade} dias a partir de {data}
                                </small>
                            ) : null}
                            {validade === null && (
                                <small className="campo-amarelo">
                                    Validade: {data}
                                </small>
                            )}
                        </p>
                    </div>
                </div>

                <div className="container-btns">
                    <button
                        className="tutorial"
                        onClick={() => props.setModalTutorial(true)}
                    >
                        Como Usar Meu Voucher
                    </button>
                </div>

                {/* <div className="footer">
                    <div className="container-img">
                        <img src={imgMensagem} alt="" />
                    </div>

                    <p>
                        Dúvidas?{" "}
                        <a href="https://api.whatsapp.com/send?phone=551130049136&text=Ol%C3%A1!%20Gostaria%20de%20ajuda">
                            Clique aqui
                        </a>{" "}
                        e fale com o Ton
                    </p>
                </div> */}
            </div>
        </Container>
    );
}
